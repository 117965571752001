import classNames from "classnames";
import { allowedNodeEnvironmentFlags } from "process";
import { ChangeEvent, ChangeEventHandler, forwardRef, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { PropsWithClass } from "utils/props";

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'setValue' | 'onChange' | 'placeholder'> & PropsWithClass<{
  value?: string
  setValue?: (v: string, e: ChangeEvent<HTMLInputElement>) => void
  onChange?: (v: string, e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}>

export const inputClassNames = classNames("flex",
  "items-center",
  "border-light-grey border-[0.5px] px-0.5 p-0.2 rounded-md",
  "placeholder:font-medium placeholder:text-grey",
  'focus-outline', 'active:!ring-0',
  'disabled:text-grey',
)

const Input = forwardRef<HTMLInputElement, InputProps>(({
  value,
  setValue,
  onChange,
  placeholder,
  className,
  ...all
}, ref) => {
  const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (onChange) {
      onChange(e.target.value, e)
    }
    if (setValue) {
      setValue(e.target.value, e);
    }
  };
  return (
    <input
      className={twMerge(classNames(
        inputClassNames,
        className
      ))}
      onChange={handleInput}
      placeholder={placeholder}
      value={value}
      ref={ref}
      {...all}
    />
  );
})


Input.displayName = "Input";


export { Input } 
